import {
	ChatBot,
	ErrorPage,
	Footer,
	FooterAlt,
	FooterLite,
	Header,
	PageView,
} from '@aspendental/shared-components-web';
import { persistedExchange } from '@urql/exchange-persisted';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { initUrqlClient, withUrqlClient } from 'next-urql';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { cacheExchange, fetchExchange, ssrExchange, useQuery } from 'urql';

import getConfigByBrand from './utils/get-config-by-brand';
import getThemeByDomainName from './utils/get-theme-by-domain-name';
import { contentfulGraphQLURL } from '../../src/constants';

import { AppMetadataByAppNameDocument, AppPagesDataByAppNameDocument } from '@src/lib/__generated/sdk';
import { getLogger } from '@src/lib/logging/logger';
import { getServerSideTranslations } from '@src/pages/utils/get-serverside-translations';
import { IGoogleMap } from '@aspendental/shared-components-web/lib/components/GoogleMap/GoogleMap';

const CustomErrorPage: NextPageWithLayout = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
	const [isClient, setIsClient] = useState(false);
	useEffect(() => {
		setIsClient(true);
	}, []);

	const { config } = props;
	const { statusCode } = config;
	const { features, featureFlags } = config as BrandThemeConfig;
	const appName = config.contentfulAppId || process.env.TAG_APP_NAME || 'TAG_Commercial_Web_Sandbox';
	const [{ data: appPagesData }] = useQuery({
		query: AppPagesDataByAppNameDocument,
		variables: { appName },
	});
	const [{ data: appMetadata }] = useQuery({
		query: AppMetadataByAppNameDocument,
		variables: { appName },
	});
	const appData = {
		...appPagesData?.appCollection?.items[0],
		...appMetadata?.appCollection?.items[0],
	};
	const withFixedHeader = true;
	const logoUrl = appData?.logo?.url;
	const footerData = appData?.footer;
	const isFooterLite = features.footer.lite;
	const isFooterAlt = features.footer.alt;
	const withSimpleLayout = features.footer.withSimpleLayout;
	if (appData && appData.header?.locationBarFindOfficeUrl) {
		const brandSubdomain = props.env?.ENVIRONMENT_NAME !== 'prod' ? 'wwwstg' : 'www';
		appData.header.locationBarFindOfficeUrl = appData.header?.locationBarFindOfficeUrl?.replace(
			'[env]',
			brandSubdomain
		);
	}

	if (!isClient) return null;
	//customizable 404 pages from contentful
	const { heading, subtitle, body, buttonLabel, backgroundImage, backgroundImageMobile, darkTextColor } =
		appData?.notFoundPage || {};

	const contentfulPageProps = {
		heading: heading,
		subtitle: subtitle,
		body: body,
		buttonLabel: buttonLabel,
		backgroundImageUrl: backgroundImage?.url,
		backgroundImageUrlMobile: backgroundImageMobile?.url,
		darkTextColor: darkTextColor,
	};

	const fallbackPageProps = {
		heading: 'Oops!',
		subtitle: "The page you're looking for seems to be missing.",
		body: "Let's guide you back to the information you need.",
		buttonLabel: 'Return to Home',
		backgroundImageUrl: features.notFoundPage.desktopBackgroundImage,
		backgroundImageUrlMobile: features.notFoundPage.mobileBackgroundImage,
		darkTextColor: true,
	};

	const errorPageProps = appData?.notFoundPage ? { ...contentfulPageProps } : { ...fallbackPageProps };
	return (
		<>
			{appData && (
				<Header
					locationNavigationLinks={appData.header?.locationNavigationLinks}
					appData={appData}
					withOfficeDetails
					showHeaderNavMenu={props?.showHeaderNavMenu}
					withFixedHeader={!!withFixedHeader}
					useHeaderLocationCards={appData.header?.useHeaderLocationCards}
					hideLocation={config?.featureFlags?.hideHeaderFacilityLocator ?? false}
					hidePrimaryActionButton={config?.featureFlags?.hideHeaderSchedulingButton ?? false}
					googleMapsForLocationCards={appData?.header?.googleMapsForLocationCards as IGoogleMap}
				/>
			)}
			<PageView error={statusCode} />
			<ErrorPage {...errorPageProps} />
			<ChatBot
				url={features.chat.chatBotURL}
				isIconButton={features.chat.isIconButton}
				logoUrl={logoUrl}
				featureList={featureFlags.featureList}
			/>
			{footerData && isFooterLite ? (
				<FooterLite footerData={footerData} />
			) : footerData && isFooterAlt ? (
				<FooterAlt footerData={footerData} />
			) : (
				<Footer
					footerData={footerData}
					withScheduling={withSimpleLayout}
					isLightFooter={!!footerData?.isLightFooter}
				/>
			)}
		</>
	);
};

export const getServerSideProps: GetServerSideProps = (async ({ req, res, locale }) => {
	const domain = req.headers.host;
	const { statusCode = 404 } = res;
	const theme = getThemeByDomainName(domain);
	const headerTagTheme = Array.isArray(req.headers['x-tag-theme'])
		? (req.headers['x-tag-theme'] as TagThemeType[])[0]
		: (req.headers['x-tag-theme'] as TagThemeType);
	// Priority: HTTP header x-tag-theme > domain > process.env.TAG_BRAND_THEME
	const tagTheme: TagThemeType = headerTagTheme || theme || process.env.TAG_BRAND_THEME || 'aspendental';
	const logger = getLogger(`${statusCode}-page`, tagTheme);
	const brandConfig = getConfigByBrand(tagTheme);
	try {
		const preview = false;

		const ssrCache = ssrExchange({ isClient: false });
		const client = initUrqlClient(
			{
				url: contentfulGraphQLURL,
				exchanges: [cacheExchange, ssrCache, fetchExchange],
				fetchOptions: () => {
					return {
						headers: {
							Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
							'content-type': 'application/json',
						},
					};
				},
			},
			false
		);
		const appName = brandConfig.contentfulAppId || process.env.TAG_APP_NAME || 'TAG_Commercial_Web_Sandbox';
		const [appPagesData, appMetadata] = await Promise.all([
			client.query(AppPagesDataByAppNameDocument, { appName, preview }).toPromise(),
			client.query(AppMetadataByAppNameDocument, { appName, preview }).toPromise(),
		]);
		const appData = {
			...appPagesData.data?.appCollection?.items[0],
			...appMetadata.data?.appCollection?.items[0],
		};

		// TODO: remove this when we have a better way to get runtime environment name for static pages
		// The following way of getting environment name is not reliable because it depends on the build time.
		// Most likely that staging environment would be recognized as production for the 404 pages.
		const env = { ENVIRONMENT_NAME: process.env.NODE_ENV === 'production' ? 'prod' : 'stage' };
		// replace [env] with environment name for locationBarFindOfficeUrl
		// TODO: create a brand variable context to replace those variables from CMS in future
		if (appData && appData.header?.locationBarFindOfficeUrl) {
			const brandSubdomain = env?.ENVIRONMENT_NAME !== 'prod' ? 'wwwstg' : 'www';
			appData.header.locationBarFindOfficeUrl = appData.header?.locationBarFindOfficeUrl?.replace(
				'[env]',
				brandSubdomain
			);
		}
		if (!appData) {
			throw new Error('App data not found');
		}

		return {
			props: {
				statusCode,
				...(await getServerSideTranslations(locale)),
				env,
				config: {
					...brandConfig,
					...(!!appData.featureFlags ? { featureFlags: appData.featureFlags } : {}),
				},
				preview,
				appData,
				footerData: appData?.footer ?? null,
				showHeaderNavMenu: appData?.featureFlags?.showHeaderNavigationMenu || false,
			},
		};
	} catch (e) {
		logger.error(`Error when fetching appData: ${e}`);
		return {
			props: {
				...(await getServerSideTranslations(locale)),
				preview: false,
				appData: null,
				footerData: null,
				config: brandConfig,
				showHeaderNavMenu: true,
			},
		};
	}
}) satisfies GetServerSideProps;

CustomErrorPage.getLayout = function getLayout(page: ReactElement) {
	return <BarePageLayout {...page.props}>{page}</BarePageLayout>;
};

const BarePageLayout = ({ children }) => {
	return <>{children}</>;
};

const urqlPersistedExchange = persistedExchange({
	preferGetForPersistedQueries: true,
	enforcePersistedQueries: false,
});

const urqlExchanges = [cacheExchange, urqlPersistedExchange, fetchExchange];

export default withUrqlClient((_ssrExchange, ctx) => ({
	url: contentfulGraphQLURL,
	exchanges: urqlExchanges,
	requestPolicy: 'network-only',
	fetchOptions: () => {
		return {
			headers: {
				Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
				'content-type': 'application/json',
			},
		};
	},
}))(CustomErrorPage);
